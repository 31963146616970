import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import { updateCourse } from "../api/api";

const UpdateCourseDialog = ({
  open,
  onClose,
  selectedCourse,
  setSelectedCourse,
  setCourses,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    if (!selectedCourse?.name.trim()) return;
    setLoading(true);
    try {
      await updateCourse({
        courseData: selectedCourse,
      });
      setCourses((prevCourses) =>
        prevCourses.map((course) =>
          course.id === selectedCourse.id ? selectedCourse : course
        )
      );
      setSelectedCourse(null);
      onClose();
    } catch (error) {
      console.error("Error updating course:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNameChange = (e) => {
    const updatedName = e.target.value;
    setSelectedCourse((prev) => ({ ...prev, name: updatedName }));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        style: {
          borderRadius: 15,
        },
      }}
    >
      <DialogTitle>Edit Course</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Course Name"
          fullWidth
          autoComplete="off"
          size="small"
          variant="outlined"
          value={selectedCourse?.name || ""}
          onChange={handleNameChange}
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCourseDialog;
