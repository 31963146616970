import { Image } from "@aws-amplify/ui-react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import logo from '../assets/logo.png';

export function Header() {

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          textAlign: "center",
          bgcolor: "grey.100",
          my: '2rem'
        }}>
        <Image
          alt="logo"
          src={logo}
          style={{ width: "65px", height: "auto", display: "inline-block" }}
        />
        <Typography fontFamily="Abel" variant="h3" color="grey.700" ml={0.5}>
          factora
        </Typography>
      </Box>
      <Divider
        flexItem
        sx={{
          width: "80%",
          margin: "auto",
        }}
      />
    </>
  );
}
