import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from "@mui/material";
import { createCourse } from "../api/api";

const CreateCourseDialog = ({ open, onClose, setCourses }) => {
  const [courseName, setCourseName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    if (!courseName.trim()) {
      alert("Course Name cannot be empty.");
      return;
    }

    setLoading(true);
    try {
      const courseData = { name: courseName };
      console.log(courseData);
      const newCourse = await createCourse({ courseData: courseData });
      setCourses((prevCourses) => [newCourse, ...prevCourses]);
      setCourseName("");
      onClose();
    } catch (error) {
      console.error("Failed to create course:", error);
      alert("An error occurred while creating the course.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        style: {
          borderRadius: 15,
        },
      }}
    >
      <DialogTitle>Create Course</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Course Name"
          fullWidth
          autoComplete="off"
          size="small"
          variant="outlined"
          value={courseName}
          onChange={(e) => setCourseName(e.target.value)}
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary" disabled={loading}>
          {loading ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCourseDialog;
