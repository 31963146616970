import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  TextField,
  InputAdornment,
  FormControl,
  FormControlLabel,
  Switch,
  Typography
} from '@mui/material';
import {
  fetchDocument,
  fetchCourse
} from '../api/api';
import DocumentsDatagrid from '../components/DocumentsDatagrid';
import SearchIcon from '@mui/icons-material/Search';
import DocumentViewerDialog from './DocumentViewerDialog';

const Courses = ({
  user,
  setSnackbarAlert,
  setSnackbarMessage,
  setSnackbarOpen,
  setCurrentView,
  setSelectedCourse
}) => {
  const [loadingData, setLoadingData] = React.useState(false);
  const [documentRows, setDocumentRows] = React.useState([]);
  const [courseRows, setCourseRows] = React.useState([]);
  const [documentDialogOpen, setDocumentDialogOpen] = useState(false);
  const [blobUrl, setBlobUrl] = useState("");
  const [blobType, setBlobType] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [smartSearch, setSmartSearch] = useState(false);

  useEffect(() => {
    setLoadingData(true);
    if (user) {
      fetchDocument({ courseId: null })
        .then((data) => setDocumentRows(data))
        .catch((error) => console.error('Error fetching documents:', error))
        .finally(() => setLoadingData(false));
      fetchCourse()
        .then((data) => setCourseRows(data))
        .catch((error) => console.error('Error fetching courses:', error));
    }
  }, [user]);

  useEffect(() => {
    if (blobType) {
      setDocumentDialogOpen(true);
    }
  }, [blobType]);

  const handleSearchData = async (event) => {
    setLoadingData(true);
    if (event.key === 'Enter') {
      setSearchTerm(event.target.value);
      const filtereDocs = await fetchDocument({ courseId: null, searchTerm: event.target.value, useSemantic: smartSearch });
      setDocumentRows(filtereDocs);
      setLoadingData(false);
    }
  };

  return (
    <Box sx={{ px: 3 }}>
      <Grid container spacing={1}>
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 2, my: 2, alignItems: 'center' }}>
          <TextField
            variant="outlined"
            placeholder={smartSearch ? "Search for concepts, or simply ask a question..." : "Search documents, courses, or units..."}
            size="small"
            autoComplete='off'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearchData(e);
              }
            }}
            sx={{
              width: '45%',
              borderRadius: '10px',
              '& .MuiOutlinedInput-root': { borderRadius: '10px', bgcolor: 'white' }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <FormControl sx={{ mx: 0.5, width: '25ch' }}>
            <FormControlLabel
              label={<Typography variant="body2">Search In Documents</Typography>}
              control={
                <Switch
                  checked={smartSearch}
                  onChange={(e) => {
                    setSmartSearch(e.target.checked);
                  }
                  }
                />
              }
            />
          </FormControl>
        </Box>
        <Box sx={{ width: '100%', height: '80vh', maxHeight: '850h', overflow: 'auto', bgcolor: 'white', borderRadius: '10px' }}>
          <DocumentsDatagrid
            rows={documentRows}
            courseRows={courseRows}
            setRows={setDocumentRows}
            loadingData={loadingData}
            setBlobType={setBlobType}
            setBlobUrl={setBlobUrl}
            setSnackbarAlert={setSnackbarAlert}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSelectedCourse={setSelectedCourse}
            setCurrentView={setCurrentView}
          />
        </Box>
      </Grid>
      <DocumentViewerDialog
        open={documentDialogOpen}
        onClose={() => {
          setBlobType("");
          setBlobUrl("");
          setDocumentDialogOpen(false);
        }}
        blobUrl={blobUrl}
        blobType={blobType}
      />
    </Box>
  );
};

export default Courses;
