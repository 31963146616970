import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ArticleIcon from '@mui/icons-material/Article';
import Courses from '../components/Courses';
import Documents from '../components/Documents';
import SnackbarAlert from '../components/SnackbarAlert';

const drawerWidth = 240;

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function TemporaryDrawer({ user, signOut }) {
  const [open, setOpen] = React.useState(false);
  const [currentView, setCurrentView] = React.useState('Courses');
  const [selectedCourse, setSelectedCourse] = React.useState(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarAlert, setSnackbarAlert] = React.useState('');

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar sx={{ boxShadow: 'none' }}>
        <Toolbar
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="factora logo"
              style={{ height: '35px', width: 'auto' }}
            />
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ color: 'grey.100', fontFamily: 'Abel', ml: 0.5 }}
            >
              factora
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            bgcolor: 'grey.100',
          },
        }}
        variant="temporary"
        anchor="left"
        open={open}
        onClose={handleDrawerToggle}
      >
        <DrawerHeader>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ color: 'grey.600', fontFamily: 'Abel', ml: 1.5 }}
            >
              factora
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>
        </DrawerHeader>
        <Divider />
        <List>
          {['Courses', 'Documents'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={() => setCurrentView(text)}>
                <ListItemIcon>
                  {index % 2 === 0 ? <MenuBookIcon fontSize='small' /> : <ArticleIcon fontSize='small' />}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant='body2'>
                      {text}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={signOut}>
              <ListItemIcon>
                <LogoutIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant='body2'>
                    Logout
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Main>
        <DrawerHeader />
        {currentView === 'Courses' ? (
          <Courses
            user={user}
            selectedCourse={selectedCourse}
            setSelectedCourse={setSelectedCourse}
            setSnackbarAlert={setSnackbarAlert}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
          />
        ) : (
          <Documents
            user={user}
            setSelectedCourse={setSelectedCourse}
            setSnackbarAlert={setSnackbarAlert}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
            setCurrentView={setCurrentView}
          />
        )}
      </Main>
      <SnackbarAlert
        snackbarOpen={snackbarOpen}
        handleSnackbarClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarAlert}
      />
    </Box>
  );
}
