import { axiosInstance, checkToken } from './utils.js'


const fetchCourse = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/course/`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const fetchSummary = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/user_summary/`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};



const createCourse = async ({ courseData }) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(`/course/`, courseData);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const updateCourse = async ({ courseData }) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(`/course/`, courseData);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const deleteCourse = async ({ courseId }) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(`/course/${courseId}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const fetchDocument = async ({ courseId = null, searchTerm = null, useSemantic = false }) => {
  try {
    const token = await checkToken();
    const params = {};
    if (searchTerm) params.search_term = searchTerm;
    if (courseId) params.course_id = courseId;
    if (useSemantic) params.use_semantic_search = useSemantic;
    const url = '/document/';
    const response = await axiosInstance(token).get(url, { params });
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const fetchDocumentContent = async ({ documentId, documentName }) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/document_content/${documentId}/${documentName}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const createDocument = async ({ documentData }) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(`/document/`, documentData);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const fetchPresignedUrl = async (documentName, documentType, courseId) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(`/signed_url/`, { course_id: courseId, name: documentName, type: documentType });
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const fetchStudyGuide = async ({ courseId }) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/study_guide/${courseId}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const fetchQuiz = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/quiz/`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const updateDocumnet = async ({ documentData }) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(`/document/`, documentData);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const deleteDocument = async ({ documentId }) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(`/document/${documentId}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const fetchChat = async (courseId) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/chat/${courseId}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const createChat = async (courseId, chatData, documentId = null, documentName = null) => {
  try {
    const token = await checkToken();
    const params = {};
    if (documentId) params.document_id = documentId;
    if (documentName) params.document_name = documentName;
    const response = await axiosInstance(token).post(`/chat/${courseId}`, chatData, { params });
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const clearChat = async (courseId) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(`/chat/${courseId}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export {
  fetchCourse,
  fetchSummary,
  fetchDocument,
  fetchDocumentContent,
  createCourse,
  createDocument,
  fetchPresignedUrl,
  fetchQuiz,
  fetchStudyGuide,
  updateCourse,
  updateDocumnet,
  deleteCourse,
  deleteDocument,
  fetchChat,
  createChat,
  clearChat
};