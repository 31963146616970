import React from 'react';
import {
  Box,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  Button
} from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import SummarizeIcon from '@mui/icons-material/Summarize';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  updateDocumnet,
  deleteDocument,
  fetchDocumentContent,
  createChat,
  fetchDocument
} from '../api/api';

const DocumentsDatagrid = ({
  selectedCourse,
  rows,
  setRows,
  loadingData,
  setBlobType,
  setBlobUrl,
  setLoadingResponse,
  msgs,
  setMsgs,
  shrinkDataGrid,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  courseRows = [],
  setSelectedCourse = null,
  setCurrentView = null
}) => {

  const handleFetchDocument = async (row) => {
    setBlobUrl(null);
    console.log(row);
    try {
      let data;
      if (row?.shard_name) {
        data = await fetchDocumentContent(
          {
            documentId: row.header_id,
            documentName: row.shard_name
          });
      } else {
        data = await fetchDocumentContent(
          {
            documentId: row.id,
            documentName: row.name
          });
      }
      const pdfBase64 = data;
      const byteCharacters = atob(pdfBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      let mimeType = 'application/octet-stream';
      if (row.name.endsWith('.pdf')) {
        mimeType = 'application/pdf';
      } else if (row.name.endsWith('.png')) {
        mimeType = 'image/png';
      } else if (row.name.endsWith('.jpg') || row.name.endsWith('.jpeg')) {
        mimeType = 'image/jpeg';
      } else if (row.name.endsWith('.zip')) {
        mimeType = 'application/zip';
      } else {
        mimeType = 'application/pdf';
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      if (mimeType === 'application/zip') {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', row.name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        setBlobType(mimeType);
        if (mimeType !== 'application/pdf') {
          const url = URL.createObjectURL(blob);
          setBlobUrl(url);
        } else {
          setBlobUrl(blob);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSummarize = async (row) => {
    setLoadingResponse(true);
    const userMessage = { text: 'Summarize ' + row.name, sender: 'user' };
    setMsgs((prevMsgs) => [...prevMsgs, userMessage]);

    try {
      const placeholderMessage = { text: 'PLACEHOLDER', sender: 'system' }
      setMsgs((prevMsgs) => [...prevMsgs, placeholderMessage]);
      const response = await createChat(selectedCourse.id, [...msgs, userMessage], row.id, row.name);

      let charIndex = 0;
      const typeInterval = setInterval(() => {
        setMsgs((prevMsgs) => {
          const updatedMsgs = [...prevMsgs];
          updatedMsgs[updatedMsgs.length - 1] = {
            ...response,
            text: response.text.slice(0, charIndex + 1),
          };
          return updatedMsgs;
        });
        charIndex++;
        if (charIndex === response.text.length) {
          clearInterval(typeInterval);
        }
      }, 5);
    } catch (error) {
      console.error('Failed to send message:', error);
    } finally {
      setLoadingResponse(false);
    }
  };


  const columns = [
    { field: 'name', headerName: 'Document', flex: 1.5, editable: true },
    {
      field: 'course_id',
      headerName: 'Course',
      flex: 1,
      renderCell: (params) => {
        const course = courseRows.find(course => course.id === params.value);
        return course ? (
          <Button
            size="small"
            variant='outlined'
            onClick={() => {
              setSelectedCourse(course)
              setCurrentView('Courses')
            }}
          >
            {course.name}
          </Button>
        ) : 'Unknown';
      },
    },
    {
      field: 'doc_type',
      headerName: 'Type',
      flex: 1,
      editable: true,
      renderCell: (params) => {
        return (
          params.value !== 'Study Guide' && params.row.is_embedded ? (
            <Select
              size="small"
              variant="standard"
              value={params.value}
              fullWidth
              sx={{
                fontSize: 'inherit',
              }}
              onChange={(event) => {
                const newValue = event.target.value;
                const updatedRow = { ...params.row, doc_type: newValue };
                processRowUpdate(updatedRow, params.row);
              }}
            >
              <MenuItem value="Syllabus/Intro">
                Syllabus/Intro
              </MenuItem>
              <MenuItem value="Lecture Notes">
                Lecture Notes
              </MenuItem>
              <MenuItem value="Problem Set">
                Problem Set
              </MenuItem>
              <MenuItem value="Supplementary">
                Supplementary
              </MenuItem>
              <MenuItem value="Other">
                Other
              </MenuItem>
            </Select>
          ) : !params.value ? (
            <span style={{ fontWeight: params.value === 'Study Guide' ? 'bold' : 'normal' }}>
              Processsing...
            </span>
          ) : (
            <span style={{ fontWeight: params.value === 'Study Guide' ? 'bold' : 'normal' }}>
              {params.value}
            </span>
          )
        );
      }
    },
    { field: 'unit', headerName: 'Unit', flex: 0.75, editable: true },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 170,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'right' }}>
          {setLoadingResponse && params.row.is_embedded && params.row.doc_type !== 'Study Guide' && (
            <>
              <Tooltip title="Summarize this document">
                <IconButton onClick={() => handleSummarize(params.row)}>
                  <SummarizeIcon fontSize='small' color='info' />
                </IconButton>
              </Tooltip>
            </>
          )}
          {(params.row.is_embedded || params.row.doc_type === 'Study Guide') && (
            <Tooltip title="View document">
              <IconButton onClick={() => handleFetchDocument(params.row)}>
                <SearchIcon fontSize='small' color='inherit' />
              </IconButton>
            </Tooltip>
          )}
          {params.row.doc_type !== 'Study Guide' && params.row.page_count && (
            <Tooltip title={params.row.is_embedded ? "Document is searchable. Page count: " + params.row.page_count : "There was an error indexing this document (there is a 100 page limit per document)."} sx={{ mx: 1 }}>
              {params.row.is_embedded ? <CheckCircleIcon fontSize='small' color='success' /> : <ErrorIcon fontSize='small' color='error' />}
            </Tooltip>
          )}
          <Tooltip title="Delete document">
            <IconButton
              onClick={async () => {
                await deleteDocument({ documentId: params.row.id });
                const newRows = rows.filter((row) => row.id !== params.row.id);
                setSnackbarAlert("success");
                setSnackbarOpen(true);
                setSnackbarMessage("Document deleted successfully");
                setRows(newRows);
              }}
            >
              <DeleteIcon fontSize='small' color='error' />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const processRowUpdate = async (newRow, oldRow) => {
    try {
      const oldRowExtension = oldRow.name.split('.').pop();
      if (!newRow.name.endsWith(`.${oldRowExtension}`)) {
        newRow.name = `${newRow.name}.${oldRowExtension}`;
      }
      if (newRow.doc_type === 'Study Guide' && oldRow.name !== newRow.name) {
        setSnackbarAlert("error");
        setSnackbarOpen(true);
        setSnackbarMessage("The study guide cannot be renamed.");
        return oldRow;
      }
      await updateDocumnet({ documentData: newRow });
      setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));
    } catch (error) {
      console.error('Error updating document:', error);
    }
    return newRow;
  };

  const handleRefresh = async () => {
    const newRows = await fetchDocument({ courseId: selectedCourse.id });
    setRows(newRows);
  };

  const Toolbar = () => {
    return (
      <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'flex-end', mr: '0.2rem' }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          variant='text'
          startIcon={<RefreshIcon />}
          onClick={handleRefresh}
        >
          Refresh
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      density="compact"
      hideFooter
      processRowUpdate={processRowUpdate}
      loading={loadingData}
      slots={{ toolbar: selectedCourse ? Toolbar : null }}
      columnVisibilityModel={{ unit: !shrinkDataGrid, create_time: !shrinkDataGrid, course_id: !(courseRows.length === 0) }}
    />
  );
};

export default DocumentsDatagrid;

