import React, { useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import Box from '@mui/material/Box';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';
import MainApp from './pages/MainApp';
import MobileApp from './pages/MobileApp';
import axios from 'axios';
import { Header } from "./components/AmplifyHeader";
import { fetchAuthSession } from 'aws-amplify/auth';
import { isMobile } from 'react-device-detect';

Amplify.configure(awsconfig);

function App({ user, signOut }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [userObj, setUserObj] = React.useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const session = await fetchAuthSession();
        const userData = await axios.get(`${apiUrl}/user/`, {
          headers: {
            Authorization: `Bearer ${session.tokens.idToken.toString()}`
          }
        });
        setUserObj(userData.data);
        localStorage.setItem('identityId', session.identityId.toString());
        localStorage.setItem('orgId', userData.data?.org_id?.toString() || '');
        localStorage.setItem('apiToken', session.tokens.idToken.toString());
      } catch (error) {
        console.error('Error fetching content:', error);
        return;
      }
    };

    fetchUser();
  }, [user, apiUrl]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: 'grey.100',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {isMobile ? (
          <MobileApp user={userObj} signOut={signOut} />
        ) : (
          <MainApp user={userObj} signOut={signOut} />
        )}
      </Box>
    </ThemeProvider>
  );
}

export default withAuthenticator(App, {
  hideSignUp: true,
  components: {
    SignIn: {
      Header: Header,
    }
  },
  socialProviders: ['google']
});