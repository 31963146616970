import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, Button, LinearProgress, IconButton, Divider, Avatar } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ClearIcon from '@mui/icons-material/Clear';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { createChat, clearChat, fetchDocumentContent } from '../api/api';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import parse from 'html-react-parser';

const Flashcard = ({ front, back }) => {
  const [flipped, setFlipped] = useState(false);

  return (
    <Box
      sx={{
        perspective: '1000px',
        width: '300px',
        height: '150px',
      }}
    >
      <Box
        onClick={() => setFlipped(!flipped)}
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          transformStyle: 'preserve-3d',
          transition: 'transform 0.6s',
          transform: flipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
          cursor: 'pointer',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backfaceVisibility: 'hidden',
            backgroundColor: '#255d99',
            color: '#fff',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'left',
            textAlign: 'left',
            padding: '10px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant='body2' sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
            {front}
          </Typography>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backfaceVisibility: 'hidden',
            backgroundColor: '#f0ad4e',
            color: 'black',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'left',
            textAlign: 'left',
            padding: '10px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            transform: 'rotateY(180deg)',
          }}
        >
          <Typography variant='body2' sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
            {back}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const ChatWindow = ({
  selectedCourse,
  shrinkDataGrid,
  setShrinkDataGrid,
  msgs,
  setMsgs,
  loadingResponse,
  setLoadingResponse,
  setBlobUrl,
  setBlobType,
  setSnackbarAlert,
  setSnackbarMessage,
  setSnackbarOpen
}) => {
  const [input, setInput] = useState('');
  const bottomRef = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [msgs]);

  const handleFetchDocument = async (row) => {
    setBlobUrl(null);
    try {
      const data = await fetchDocumentContent(
        {
          documentId: row.id,
          documentName: row.shard_name
        });
      const pdfBase64 = data;
      const byteCharacters = atob(pdfBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      let mimeType = 'application/octet-stream';
      if (row.name.endsWith('.pdf')) {
        mimeType = 'application/pdf';
      } else if (row.name.endsWith('.png')) {
        mimeType = 'image/png';
      } else if (row.name.endsWith('.jpg') || row.name.endsWith('.jpeg')) {
        mimeType = 'image/jpeg';
      } else if (row.name.endsWith('.zip')) {
        mimeType = 'application/zip';
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      if (mimeType === 'application/zip') {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', row.name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        setBlobType(mimeType);
        if (mimeType !== 'application/pdf') {
          const url = URL.createObjectURL(blob);
          setBlobUrl(url);
        } else {
          setBlobUrl(blob);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSend = async () => {
    if (input.trim()) {
      setInput('');
      const userMessage = { text: input, sender: 'user' };
      setMsgs((prevMsgs) => [...prevMsgs, userMessage]);
      setLoadingResponse(true);

      try {
        const placeholderMessage = { text: 'PLACEHOLDER', sender: 'system' }
        setMsgs((prevMsgs) => [...prevMsgs, placeholderMessage]);
        const response = await createChat(selectedCourse.id, [...msgs, userMessage]);

        let charIndex = 0;
        const typeInterval = setInterval(() => {
          setMsgs((prevMsgs) => {
            const updatedMsgs = [...prevMsgs];
            updatedMsgs[updatedMsgs.length - 1] = {
              ...response,
              text: response.text.slice(0, charIndex + 1),
            };
            return updatedMsgs;
          });
          charIndex++;
          if (charIndex === response.text.length) {
            clearInterval(typeInterval);
          }
        }, 5);
      } catch (error) {
        console.error('Failed to send message:', error);
        setSnackbarAlert('error');
        setSnackbarMessage('Failed to send message, please retry momentarily.');
        setSnackbarOpen(true);
      } finally {
        setLoadingResponse(false);
        setInput('');
      }
    }
  };

  const handleFollowUp = async (msg) => {
    const userMessage = { text: msg, sender: 'user' };
    setMsgs((prevMsgs) => [...prevMsgs, userMessage]);
    setLoadingResponse(true);

    try {
      const placeholderMessage = { text: 'PLACEHOLDER', sender: 'system' }
      setMsgs((prevMsgs) => [...prevMsgs, placeholderMessage]);
      const response = await createChat(selectedCourse.id, [...msgs, userMessage]);

      let charIndex = 0;
      const typeInterval = setInterval(() => {
        setMsgs((prevMsgs) => {
          const updatedMsgs = [...prevMsgs];
          updatedMsgs[updatedMsgs.length - 1] = {
            ...response,
            text: response.text.slice(0, charIndex + 1),
          };
          return updatedMsgs;
        });
        charIndex++;
        if (charIndex === response.text.length) {
          clearInterval(typeInterval);
        }
      }, 5);
    } catch (error) {
      console.error('Failed to send message:', error);
      setSnackbarAlert('error');
      setSnackbarMessage('Failed to send message, please retry momentarily.');
      setSnackbarOpen(true);
    } finally {
      setLoadingResponse(false);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '85vh',
        maxHeight: '85vh',
        bgcolor: 'transparent',
        borderRadius: '10px',
        border: '1px solid #ccc',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mx: '5px', p: '5px', bgcolor: 'transparent' }}>
        <IconButton onClick={() => setShrinkDataGrid(!shrinkDataGrid)}>
          {shrinkDataGrid ? <ArrowForwardIosIcon fontSize='small' /> : <ArrowBackIosIcon fontSize='small' />}
        </IconButton>
        {msgs.length > 0 && (
          <Button
            variant='text'
            startIcon={<ClearIcon fontSize="small" />}
            onClick={async () => {
              await clearChat(selectedCourse.id)
              setMsgs([]);
            }}
            color='error'
          >
            Clear Chat
          </Button>
        )}
      </Box>

      {msgs.length > 0 ? (
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            bgcolor: 'transparent',
            px: '15px'
          }}
        >

          {msgs.map((msg, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginBottom: '8px',
              }}
            >
              <Typography
                variant="body1"
                component={'div'}
                sx={{
                  maxWidth: '100%',
                  padding: '10px',
                  borderRadius: '10px',
                  bgcolor: 'transparent',
                  color: 'grey.800',
                  textAlign: 'left',
                  wordWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {msg.sender === 'user' ? (
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'10px'} mb={'0px'}>
                    <Avatar
                      alt="logo_icon"
                      src={`${process.env.PUBLIC_URL}/logo.png`}
                      sx={{
                        width: 35,
                        height: 35,
                        padding: '5px',
                        bgcolor: 'grey.600',
                      }}
                    />
                    <Typography variant="h6" fontWeight={600} color='grey.900'>{msg.text}</Typography>
                  </Box>
                ) : msg.text === 'PLACEHOLDER' && loadingResponse ? (
                  <Typography variant="body2" className="glowing-text">
                    Thinking...
                  </Typography>
                ) : (
                  parse(msg.text.replace(/^<html>|<\/html>$/g, '').trim())
                )}
                {msg.msg_metadata?.documents && msg.msg_metadata.documents.length > 0 && (
                  <>
                    <Typography variant="body2" fontWeight={600} sx={{ mt: '10px' }}>Sources</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '5px',
                        mt: '0px',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                        width: '100%',
                      }}
                    >
                      {msg.msg_metadata.documents.map((document, documentIndex) => (
                        <Button
                          key={`${index}-document-${documentIndex}`}
                          startIcon={<FindInPageIcon fontSize='small' sx={{ color: 'grey.700' }} />}
                          onClick={() => handleFetchDocument(document)}
                          variant='text'
                          sx={{
                            cursor: 'pointer',
                            bgcolor: 'transparent',
                            mt: '3px',
                            borderRadius: '8px',
                            color: 'grey.800',
                            fontSize: '12px',
                            justifyContent: 'flex-start',
                            textTransform: 'none',
                          }}
                        >
                          {document.name} {document.page_number ? ` (page: ${document.page_number})` : ''}
                        </Button>
                      ))}
                    </Box>
                  </>
                )}
                {msg.msg_metadata?.flash_cards && msg.msg_metadata.flash_cards.length > 0 && (
                  <>
                    <Typography variant="body2" fontWeight={600} sx={{ mt: '10px' }}>Flashcards</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '16px',
                        my: '10px',
                        justifyContent: 'flex-start',
                      }}
                    >
                      {msg.msg_metadata.flash_cards.map((item, index) => (
                        <Flashcard key={index} front={item.front} back={item.back} />
                      ))}
                    </Box>
                  </>
                )}

                {msg.msg_metadata?.follow_ups && msg.msg_metadata.follow_ups.length > 0 && (
                  <>
                    <Typography variant="body2" fontWeight={600} sx={{ mt: '10px' }}>Related</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0px',
                        mt: '0px',
                        alignItems: 'flex-start',
                      }}
                    >

                      {msg.msg_metadata.follow_ups.map((metadata, metaIndex) => (
                        <Button
                          key={metaIndex}
                          variant='text'
                          startIcon={<AddIcon sx={{ color: 'grey.700' }} />}
                          onClick={() => handleFollowUp(metadata)}
                          fullWidth
                          sx={{
                            color: 'grey.700',
                            textAlign: 'left',
                            justifyContent: 'flex-start',
                          }}
                        >
                          {metadata}
                        </Button>
                      ))}
                    </Box>
                  </>
                )}
              </Typography>
              {msg.text !== 'PLACEHOLDER' && msg.sender !== 'user' && <Divider sx={{ my: '5px', width: '100%' }} />}
            </Box>
          ))}
          <div ref={bottomRef} />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            flexGrow: 1,
          }}
        >
          <Typography variant="body1" display={'flex'} color='grey.800'>
            Ask a question about this course or click <SummarizeIcon color='info' sx={{ mx: '5px' }} /> to summarize the document.
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          p: '5px',
          border: '1px solid #027d95',
          borderRadius: '8px',
          mx: '10px',
          mb: '10px',
          mt: '2px',
          bgcolor: 'transparent',
        }}
      >
        <TextField
          fullWidth
          size="small"
          autoComplete="off"
          placeholder="Message Factora"
          variant="outlined"
          value={input}
          multiline
          autoFocus
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSend();
            }
          }}
          sx={{
            marginRight: '8px',
            maxHeight: '30vh',
            overflow: 'auto',
            bgcolor: 'transparent',
            '& .MuiOutlinedInput-root': {
              border: 'none',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        />
        {loadingResponse ? (
          <LinearProgress sx={{ width: '100px', height: '5px' }} />
        ) : (
          <IconButton
            disabled={input.trim() === ''}
            onClick={handleSend}
            sx={{
              color: 'white',
              boxShadow: 'none',
              border: '0.5px solid grey',
              padding: '5px',
              bgcolor: '#027d95',
            }}
          >
            <ArrowUpwardIcon fontSize='small' />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default ChatWindow;
