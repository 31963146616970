import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
  Divider,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Tooltip,
  LinearProgress
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import GradingIcon from '@mui/icons-material/Grading';
import AddIcon from '@mui/icons-material/Add';
import {
  fetchCourse,
  deleteCourse,
  createDocument,
  fetchDocument,
  fetchChat,
  fetchStudyGuide,
  fetchPresignedUrl,
  fetchSummary
} from '../api/api';
import CreateCourseDialog from './CreateCourseDialog';
import UpdateCourseDialog from './UpdateCourseDialog';
import DocumentsDatagrid from './DocumentsDatagrid';
import DocumentViewerDialog from './DocumentViewerDialog';
import ChatWindow from './ChatWindow';
import QuizDialog from './QuizDialog';
import axios from 'axios';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';


const Courses = ({
  user,
  selectedCourse,
  setSelectedCourse,
  setSnackbarAlert,
  setSnackbarMessage,
  setSnackbarOpen
}) => {
  const [editingCourse, setEditingCourse] = useState("");
  const [courses, setCourses] = React.useState([]);
  const [courseEditingDialogOpen, setCourseEditingDialogOpen] = React.useState(false);
  const [courseDialogOpen, setCourseDialogOpen] = React.useState(false);
  const [documentDialogOpen, setDocumentDialogOpen] = useState(false);
  const [loadingData, setLoadingData] = React.useState(true);
  const [loadingResponse, setLoadingResponse] = React.useState(false);
  const [shrinkDataGrid, setShrinkDataGrid] = React.useState(false);
  const [loadingStudyGuide, setLoadingStudyGuide] = React.useState(false);
  // const [loadingQuiz, setLoadingQuiz] = React.useState(false);
  const [quizDialogOpen, setQuizDialogOpen] = React.useState(false);
  const [quizQuestions, setQuizQuestions] = React.useState([]);
  const [loadingUpload, setLoadingUpload] = React.useState(false);
  const [msgs, setMsgs] = useState([]);
  const [documentRows, setDocumentRows] = React.useState([]);
  const [userSummary, setUserSummary] = React.useState([]);
  const [blobUrl, setBlobUrl] = useState(null);
  const [blobType, setBlobType] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const fileInputRefs = useRef({});

  useEffect(() => {
    setLoadingData(true);
    if (user) {
      fetchCourse(user)
        .then((data) => setCourses(data))
        .catch((error) => console.error('Error fetching courses:', error))
        .finally(() => setLoadingData(false));
      fetchSummary()
        .then((data) => setUserSummary(data))
        .catch((error) => console.error('Error fetching summary:', error))
        .finally(() => setLoadingData(false));
    }
  }, [user]);

  useEffect(() => {
    if (selectedCourse) {
      setBlobType('');
      setBlobUrl('');
      setSearchTerm('');
      setLoadingData(true);
      setMsgs([]);
      fetchDocument({ courseId: selectedCourse.id })
        .then((data) => setDocumentRows(data))
        .catch((error) => console.error('Error fetching courses:', error))
        .finally(() => setLoadingData(false));
      fetchChat(selectedCourse.id)
        .then((data) => setMsgs(data))
        .catch((error) => console.error('Error fetching courses:', error));
    }
  }, [selectedCourse]);

  useEffect(() => {
    if (blobType) {
      setDocumentDialogOpen(true);
    }
  }, [blobType]);


  const handleDeleteCourse = async (id) => {
    await deleteCourse({ courseId: id });
    setCourses(courses.filter((course) => course.id !== id));
  };

  const handleUploadClick = (rowId) => {
    const inputRef = getFileInputRef(rowId);
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (event, course_id) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      Array.from(files).forEach((file) => {
        if (file.type === "application/pdf") {
          uploadDocument(file, course_id);
        } else if (file.type === "application/zip") {
          uploadZipFile(file, course_id);
        } else {
          setSnackbarAlert("error");
          setSnackbarMessage("Only PDF files are allowed.");
          setSnackbarOpen(true);
        }
      });
    }
  };

  const uploadDocument = async (file, course_id) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      try {
        const b64encodedString = reader.result.split(",")[1];
        const data = await createDocument({
          documentData: { course_id: course_id, name: file.name, file_data: b64encodedString },
        });
        setDocumentRows((prevRows) => [data, ...prevRows]);
      } catch (error) {
        const backendMessage = error.response?.data?.detail || "Error uploading document.";
        setSnackbarAlert("error");
        setSnackbarMessage(backendMessage);
        setSnackbarOpen(true);
      }
    };
  };
  ;

  const uploadZipFile = async (file, course_id) => {
    setLoadingUpload(true);
    try {
      const signedUrl = await fetchPresignedUrl(file.name, file.type, course_id);
      await axios.put(signedUrl.signed_url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      setSnackbarAlert("success");
      setSnackbarMessage(`File ${file.name} uploaded successfully.`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error uploading file:", error);
      const backendMessage = error.response?.data?.detail || "Error uploading document.";
      setSnackbarAlert("error");
      setSnackbarMessage(backendMessage);
      setSnackbarOpen(true);
    } finally {
      setLoadingUpload(false);
    }
  };

  const getFileInputRef = (rowId) => {
    if (!fileInputRefs.current[rowId]) {
      fileInputRefs.current[rowId] = React.createRef();
    }
    return fileInputRefs.current[rowId];
  };

  const handleSearchData = async (event) => {
    setLoadingData(true);
    if (event.key === 'Enter') {
      setSearchTerm(event.target.value);
      const filtereDocs = await fetchDocument({ courseId: selectedCourse.id, searchTerm: event.target.value });
      setDocumentRows(filtereDocs);
      setLoadingData(false);
    }
  };

  const handleCreateStudyGuide = async () => {
    try {
      setLoadingStudyGuide(true);
      const response = await fetchStudyGuide({ courseId: selectedCourse.id });
      if (response) {
        setDocumentRows((prevRows) => [response, ...prevRows]);
      } else {
        setSnackbarAlert("info");
        setSnackbarMessage("The study guide already exists.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error creating study guide:", error);
    } finally {
      setLoadingStudyGuide(false);
    }
  }

  // const handleCreateQuiz = async () => {
  //   try {
  //     setLoadingQuiz(true);
  //     const response = await fetchQuiz();
  //     setQuizQuestions(response.body.quiz);
  //     setQuizDialogOpen(true);
  //   } catch (error) {
  //     console.error("Error creating quiz:", error);
  //   } finally {
  //     setLoadingQuiz(false);
  //   }
  // }


  return (
    <Box sx={{ px: 0.5 }}>
      <Grid container spacing={0.5}>
        {loadingData ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '70vh',
            }}
          >
            <CircularProgress size={20} />
            <Typography variant="body1" sx={{ ml: 1 }}>
              Loading...
            </Typography>
          </Box>
        ) : selectedCourse ? (
          <>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', px: 1 }}>
                <Button
                  onClick={() => {
                    setSelectedCourse(null)
                    setMsgs([])
                    setDocumentRows([])
                  }}
                  startIcon={<ArrowBackIosIcon />}
                >
                  Back
                </Button>
                <Typography variant="body1" component="div" sx={{ color: 'grey.700' }}>
                  {selectedCourse?.name}
                </Typography>
              </Box>
            </Grid>
            <Divider width="100%" sx={{ my: 1 }} />
            <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row' }}>
              {!shrinkDataGrid && (
                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', gap: 1, mb: 1 }}>
                    {!shrinkDataGrid && (
                      <>
                        {loadingUpload ? (
                          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} width={'40%'} mx={1}>
                            <Typography variant="caption" sx={{ fontSize: '0.7rem' }}>Uploading...</Typography>
                            <LinearProgress sx={{ width: '100%' }} />
                          </Box>
                        ) : (
                          <>
                            <Tooltip title="Upload individual PDF files (<10MB) or Zipped Archive">
                              <Button
                                variant="contained"
                                startIcon={<UploadIcon />}
                                fullWidth
                                sx={{
                                  width: '40%',
                                  borderRadius: '10px',
                                  boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.1)'
                                }}
                                onClick={() => handleUploadClick(selectedCourse.id)}
                              >
                                Upload File(s)
                              </Button>
                            </Tooltip>
                            <input
                              type="file"
                              ref={getFileInputRef(selectedCourse.id)}
                              style={{ display: 'none' }}
                              multiple
                              onChange={(e) => handleFileChange(e, selectedCourse.id)}
                            />
                          </>
                        )}
                        {loadingStudyGuide ? (
                          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} width={'40%'} mx={1}>
                            <Typography variant="caption" sx={{ fontSize: '0.7rem' }}>Creating Study Guide...</Typography>
                            <LinearProgress sx={{ width: '100%' }} />
                          </Box>
                        ) : (
                          <Tooltip title="Create a study guide from 'Lecture Notes' documents.">
                            <Button
                              variant="contained"
                              fullWidth
                              startIcon={<GradingIcon />}
                              onClick={handleCreateStudyGuide}
                              sx={{
                                width: '40%',
                                borderRadius: '10px',
                                bgcolor: '#0c5394',
                                boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.1)'
                              }}
                            >
                              Study Guide
                            </Button>
                          </Tooltip>
                        )}
                      </>
                    )}
                    <TextField
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      value={searchTerm}
                      placeholder='Search Documents'
                      fullWidth
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearchData(e);
                        }
                      }}
                      sx={{
                        borderRadius: '10px',
                        '& .MuiOutlinedInput-root': { borderRadius: '10px', bgcolor: 'white' },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box sx={{ width: '100%', height: '80vh', maxHeight: '80vh', overflow: 'auto', bgcolor: 'white', borderRadius: '10px' }}>
                    <DocumentsDatagrid
                      selectedCourse={selectedCourse}
                      rows={documentRows}
                      setRows={setDocumentRows}
                      loadingData={loadingData}
                      setBlobType={setBlobType}
                      setBlobUrl={setBlobUrl}
                      setLoadingResponse={setLoadingResponse}
                      msgs={msgs}
                      setMsgs={setMsgs}
                      shrinkDataGrid={shrinkDataGrid}
                      setSnackbarAlert={setSnackbarAlert}
                      setSnackbarOpen={setSnackbarOpen}
                      setSnackbarMessage={setSnackbarMessage}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={shrinkDataGrid ? 12 : 6} sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <ChatWindow
                  shrinkDataGrid={shrinkDataGrid}
                  setShrinkDataGrid={setShrinkDataGrid}
                  selectedCourse={selectedCourse}
                  msgs={msgs}
                  setMsgs={setMsgs}
                  loadingResponse={loadingResponse}
                  setLoadingResponse={setLoadingResponse}
                  setBlobType={setBlobType}
                  setBlobUrl={setBlobUrl}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', p: 1 }}>
              <Box display={'flex'} flexDirection={'row'} gap={1}>
                <Button
                  variant='contained'
                  startIcon={<AddIcon />}
                  sx={{ minWidth: '200px', boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.1)' }}
                  onClick={() => setCourseDialogOpen(true)}
                >
                  Add Course
                </Button>
                {/* {loadingQuiz ? (
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} width={'200px'} mx={1}>
                    <Typography variant="caption" sx={{ fontSize: '0.7rem', mb: '0.5rem' }}>Creating quiz...</Typography>
                    <LinearProgress sx={{ width: '100%' }} />
                  </Box>

                ) : courses.length > 0 ? (
                  <Button
                    variant='contained'
                    startIcon={<QuizIcon />}
                    sx={{ minWidth: '200px', bgcolor: '#0c5394', boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.1)' }}
                    onClick={handleCreateQuiz}
                  >
                    Quiz Me
                  </Button>
                ) : null} */}
              </Box>
              <Box display={'flex'} flexDirection={'row'} gap={1}>
                <Typography variant="body1" sx={{ color: 'grey.700' }}>{courses.length} Courses |</Typography>
                <Typography variant="body1" sx={{ color: 'grey.700' }}>
                  {new Intl.NumberFormat('en-US').format(userSummary?.document_count || 0)} Documents |
                </Typography>
                <Typography variant="body1" sx={{ color: 'grey.700' }}>
                  {new Intl.NumberFormat('en-US').format(userSummary?.total_pages || 0)} Pages
                </Typography>
              </Box>
            </Box>
            {courses.map((course) => (
              <Grid item xs={12} sm={6} md={4} key={course.id} sx={{ p: 2 }}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    border: '0.75px solid #027d95',
                    borderRadius: '10px',
                    boxShadow: 'none',
                  }}
                >
                  <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography variant="body1" fontWeight="bold" color="primary" component="div" sx={{ cursor: 'pointer' }} onClick={() => setSelectedCourse(course)}>
                        {course.name}
                      </Typography>
                      <Box display="flex" flexDirection="row">
                        <IconButton
                          size="small"
                          sx={{ color: 'grey.500' }}
                          onClick={() => {
                            setCourseEditingDialogOpen(true);
                            setEditingCourse(course);
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton size="small" sx={{ color: 'grey.600' }} onClick={() => handleDeleteCourse(course.id)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      <Typography variant="body2" color="text.secondary">
                        Created: {(() => {
                          const date = new Date(course.create_time);
                          const zonedDate = fromZonedTime(date, 'UTC');
                          return format(zonedDate, 'MM/dd/yy hh:mm a');
                        })()}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))
            }
          </>
        )}
      </Grid>
      <CreateCourseDialog
        open={courseDialogOpen}
        onClose={() => setCourseDialogOpen(false)}
        setCourses={setCourses}
      />
      <UpdateCourseDialog
        open={courseEditingDialogOpen}
        onClose={() => setCourseEditingDialogOpen(false)}
        selectedCourse={editingCourse}
        setSelectedCourse={setEditingCourse}
        setCourses={setCourses}
      />
      <DocumentViewerDialog
        open={documentDialogOpen}
        onClose={() => {
          setBlobUrl('');
          setBlobType('');
          setDocumentDialogOpen(false)
        }}
        blobUrl={blobUrl}
        blobType={blobType}
      />
      <QuizDialog
        open={quizDialogOpen}
        onClose={() => {
          setQuizQuestions([]);
          setQuizDialogOpen(false)
        }}
        quizData={quizQuestions}
      />
    </Box >
  );
};

export default Courses;
