import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Box,
  CircularProgress,
} from '@mui/material';
import {
  fetchCourse,
  fetchChat,
} from '../api/api';
import DocumentViewerDialog from './DocumentViewerDialog';
import ChatWindowMobile from './ChatWindowMobile';


const CoursesMobile = ({
  user,
  selectedCourse,
  setSelectedCourse,
  msgs,
  setMsgs
}) => {
  const [courses, setCourses] = React.useState([]);
  const [documentDialogOpen, setDocumentDialogOpen] = useState(false);
  const [loadingData, setLoadingData] = React.useState(true);
  const [loadingResponse, setLoadingResponse] = React.useState(false);
  const [blobUrl, setBlobUrl] = useState(null);
  const [blobType, setBlobType] = useState(null);

  useEffect(() => {
    setLoadingData(true);
    if (user) {
      fetchCourse(user)
        .then((data) => setCourses(data))
        .catch((error) => console.error('Error fetching courses:', error))
        .finally(() => setLoadingData(false));
    }
  }, [user]);

  useEffect(() => {
    if (selectedCourse) {
      setBlobType('');
      setBlobUrl('');
      setLoadingData(true);
      setMsgs([]);
      fetchChat(selectedCourse.id)
        .then((data) => setMsgs(data))
        .catch((error) => console.error('Error fetching courses:', error))
        .finally(() => setLoadingData(false));
    }
  }, [selectedCourse, setMsgs]);


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
      }}
    >
      <Grid container sx={{ flexGrow: 1 }}>
        {loadingData ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <CircularProgress size={20} />
            <Typography variant="body1" sx={{ ml: 1 }}>
              Loading...
            </Typography>
          </Box>
        ) : selectedCourse ? (
          <ChatWindowMobile
            selectedCourse={selectedCourse}
            msgs={msgs}
            setMsgs={setMsgs}
            loadingResponse={loadingResponse}
            setLoadingResponse={setLoadingResponse}
            setBlobType={setBlobType}
            setBlobUrl={setBlobUrl}
          />
        ) : (
          <>
            {courses.map((course) => (
              <Grid item xs={12} sm={6} md={4} key={course.id} sx={{ p: 0.5 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    my: 0.5,
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="primary"
                    component="div"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setSelectedCourse(course)}
                  >
                    {course.name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </>
        )}
      </Grid>
      <DocumentViewerDialog
        open={documentDialogOpen}
        onClose={() => {
          setBlobUrl('');
          setBlobType('');
          setDocumentDialogOpen(false);
        }}
        blobUrl={blobUrl}
        blobType={blobType}
      />
    </Box>
  );
};

export default CoursesMobile;
