import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Divider
} from "@mui/material";

const QuizDialog = ({ quizData, open, onClose }) => {
  const questions = quizData ?? [];

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [isAnswered, setIsAnswered] = useState(false);
  const [correctCount, setCorrectCount] = useState(0);

  const currentQuestion = questions[currentQuestionIndex];

  const handleAnswerChange = (event) => {
    setSelectedAnswer(Number(event.target.value));
  };

  const handleSubmit = () => {
    if (selectedAnswer === null) return;
    const isCorrect = selectedAnswer === currentQuestion.correct_answer;
    setFeedback(isCorrect ? "Correct!" : "Wrong answer!");
    if (!isCorrect) {
      setFeedback(
        `Wrong answer! Correct answer: ${currentQuestion.possible_answers[currentQuestion.correct_answer]
        }`
      );
    }
    if (isCorrect) {
      setCorrectCount((prev) => prev + 1);
    }
    setIsAnswered(true);
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length) {
      setCurrentQuestionIndex((prev) => prev + 1);
      setSelectedAnswer(null);
      setFeedback(null);
      setIsAnswered(false);
    } else {
      setIsAnswered(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setCurrentQuestionIndex(0);
      setSelectedAnswer(null);
      setFeedback(null);
      setIsAnswered(false);
      setCorrectCount(0);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{ "& .MuiPaper-root": { borderRadius: "10px" } }}
    >
      {currentQuestionIndex < questions.length ? (
        <>
          <DialogTitle fontWeight={"bold"}>
            {currentQuestion?.course_name}
          </DialogTitle>
          <DialogContent>
            <Typography variant="h6" gutterBottom>
              {currentQuestion?.question_text}
            </Typography>
            <Divider sx={{ marginBottom: 2 }} />
            <FormControl component="fieldset">
              <RadioGroup value={selectedAnswer} onChange={handleAnswerChange}>
                {currentQuestion?.possible_answers.map((answer, index) => (
                  <FormControlLabel
                    key={index}
                    value={index}
                    control={<Radio />}
                    label={
                      feedback &&
                        feedback.includes("Correct answer") &&
                        index === currentQuestion.correct_answer ? (
                        <Typography fontWeight="bold" color="success.main">
                          {answer}
                        </Typography>
                      ) : (
                        answer
                      )
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {feedback && (
              <Typography
                variant="subtitle1"
                color={
                  feedback.startsWith("Correct") ? "success.main" : "error.main"
                }
                sx={{ marginTop: 2 }}
              >
                {feedback}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            {!isAnswered ? (
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                disabled={selectedAnswer === null}
              >
                Submit
              </Button>
            ) : (
              <Button
                onClick={handleNext}
                variant="contained"
                color="primary"
              >
                {currentQuestionIndex < questions.length - 1
                  ? "Next"
                  : "Finish"}
              </Button>
            )}
          </DialogActions>
        </>
      ) : (
        <DialogContent>
          <Typography variant="h5" textAlign="center" gutterBottom>
            Quiz Complete!
          </Typography>
          <Typography variant="subtitle1" textAlign="center">
            You got {correctCount} out of {questions.length} correct.
          </Typography>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default QuizDialog;
