import React from 'react';
import { AppBar, BottomNavigation, BottomNavigationAction, Box, Container, Toolbar, Typography, Button } from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CoursesMobile from '../components/CoursesMobile';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function MobileApp({ user }) {
  const [value, setValue] = React.useState(0);
  const [selectedCourse, setSelectedCourse] = React.useState(null);
  const [msgs, setMsgs] = React.useState([]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <AppBar position="fixed" sx={{ top: 0, backgroundColor: 'primary.main', boxShadow: 'none', height: '50px', zIndex: 1100 }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {selectedCourse ? (
            <Button
              variant="outlined"
              size='small'
              startIcon={<ArrowBackIosIcon />}
              sx={{ color: 'grey.200' }}
              onClick={() => {
                setMsgs([]);
                setSelectedCourse(null);
              }}
            >
              Back
            </Button>
          ) : <Box />}
          <Box display={'flex'} alignItems={'center'} flexDirection={'row'}>
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="factora logo"
              style={{ height: '30px', width: 'auto' }}
            />
            <Typography variant="h5" component="div" sx={{ flexGrow: 1, ml: 0.5, color: 'grey.200' }} fontFamily={'Abel'}>
              factora
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>

      <Container
        sx={{
          flexGrow: 1,
          padding: '0px',
          overflowY: 'auto',
          marginTop: '50px',
          marginBottom: '50px',
        }}
      >
        {value === 0 && (
          <CoursesMobile
            user={user}
            selectedCourse={selectedCourse}
            setSelectedCourse={setSelectedCourse}
            msgs={msgs}
            setMsgs={setMsgs}
          />
        )}
      </Container>

      <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, zIndex: 1100 }}>
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
          showLabels
          sx={{
            backgroundColor: 'primary.main',
            color: 'common.white',
            height: '50px',
          }}
        >
          <BottomNavigationAction
            label="Courses"
            icon={<MenuBookIcon fontSize='small' />}
            sx={{
              color: 'grey.300',
              '&.Mui-selected': { color: 'common.white' },
            }}
          />
        </BottomNavigation>
      </AppBar>
    </Box>
  );
}

export default MobileApp;
