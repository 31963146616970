import React from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Divider,
} from '@mui/material';
import PDFViewer from './PDFViewer';

const DocumentViewerDialog = ({ open, onClose, blobUrl, blobType }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 10,
        },
      }}
    >
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '80vh',
        }}
      >
        <Divider orientation="vertical" flexItem sx={{ height: '100%', mx: 2 }} />
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          height="100%"
        >
          {blobType === 'application/pdf' ? (
            <PDFViewer pdfBlob={blobUrl} />
          ) : blobType ? (
            <img
              src={blobUrl}
              alt="Document"
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          ) : null}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DocumentViewerDialog;
