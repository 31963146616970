import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, Button, LinearProgress, IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { createChat } from '../api/api';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import parse from 'html-react-parser';

const StyledContainer = styled('div')(({ theme }) => ({
  '& h3': {
    fontSize: '18px',
  },
  '& p': {
    fontSize: '14px',
  },
}));

const Flashcard = ({ front, back }) => {
  const [flipped, setFlipped] = useState(false);

  return (
    <Box
      sx={{
        perspective: '1000px',
        width: '100%',
        height: '100px',
      }}
    >
      <Box
        onClick={() => setFlipped(!flipped)}
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          transformStyle: 'preserve-3d',
          transition: 'transform 0.6s',
          transform: flipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
          cursor: 'pointer',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backfaceVisibility: 'hidden',
            backgroundColor: '#255d99',
            color: '#fff',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'left',
            textAlign: 'left',
            padding: '10px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant='subtitle2' sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
            {front}
          </Typography>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backfaceVisibility: 'hidden',
            backgroundColor: '#f0ad4e',
            color: 'black',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'left',
            textAlign: 'left',
            padding: '10px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            transform: 'rotateY(180deg)',
          }}
        >
          <Typography variant='subtitle2' sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
            {back}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const ChatWindowMobile = ({
  selectedCourse,
  msgs,
  setMsgs,
  loadingResponse,
  setLoadingResponse,
  setBlobUrl,
  setBlobType,
  setSnackbarAlert,
  setSnackbarMessage,
  setSnackbarOpen
}) => {
  const [input, setInput] = useState('');
  const bottomRef = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [msgs]);

  const handleSend = async () => {
    if (input.trim()) {
      setInput('');
      const userMessage = { text: input, sender: 'user' };
      setMsgs((prevMsgs) => [...prevMsgs, userMessage]);
      setLoadingResponse(true);

      try {
        const response = await createChat(selectedCourse.id, [...msgs, userMessage]);
        setMsgs((prevMsgs) => [...prevMsgs, response]);
      } catch (error) {
        console.error('Failed to send message:', error);
        setSnackbarAlert('error');
        setSnackbarMessage('Failed to send message, please retry momentarily.');
        setSnackbarOpen(true);
      } finally {
        setLoadingResponse(false);
        setInput('');
      }
    }
  };

  const handleFollowUp = async (msg) => {
    const userMessage = { text: msg, sender: 'user' };
    setMsgs((prevMsgs) => [...prevMsgs, userMessage]);
    setLoadingResponse(true);

    try {
      const response = await createChat(selectedCourse.id, [...msgs, userMessage]);
      setMsgs((prevMsgs) => [...prevMsgs, response]);
    } catch (error) {
      console.error('Failed to send message:', error);
      setSnackbarAlert('error');
      setSnackbarMessage('Failed to send message, please retry momentarily.');
      setSnackbarOpen(true);
    } finally {
      setLoadingResponse(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: 'calc(100% - 50px)',
          bgcolor: 'transparent',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        {msgs.length > 0 ? (
          <Box
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
            }}
          >
            {msgs.map((msg, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginBottom: '8px',
                }}
              >
                <Typography
                  variant="body1"
                  component={'div'}
                  sx={{
                    maxWidth: '100%',
                    padding: '10px',
                    borderRadius: '10px',
                    bgcolor: 'transparent',
                    color: 'grey.800',
                    textAlign: 'left',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {msg.sender === 'user' ? (
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'10px'} mb={'0px'}>
                      <Typography fontSize={'18px'} fontWeight={600} color='grey.900'>{msg.text}</Typography>
                    </Box>
                  ) : msg.text === 'PLACEHOLDER' && loadingResponse ? (
                    <Typography variant="body2" className="glowing-text">
                      Thinking...
                    </Typography>
                  ) : (
                    <StyledContainer>{parse(msg.text.replace(/^<html>|<\/html>$/g, '').trim())}</StyledContainer>
                  )}
                  {msg.msg_metadata?.flash_cards && msg.msg_metadata.flash_cards.length > 0 && (
                    <>
                      <Typography variant="body2" fontWeight={600} sx={{ mt: '10px' }}>Flashcards</Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: '16px',
                          my: '10px',
                          justifyContent: 'flex-start',
                        }}
                      >
                        {msg.msg_metadata.flash_cards.map((item, index) => (
                          <Flashcard key={index} front={item.front} back={item.back} />
                        ))}
                      </Box>
                    </>
                  )}

                  {msg.msg_metadata?.follow_ups && msg.msg_metadata.follow_ups.length > 0 && (
                    <>
                      <Typography variant="body2" fontWeight={600} sx={{ mt: '10px' }}>Related</Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '0px',
                          mt: '0px',
                          alignItems: 'flex-start',
                        }}
                      >

                        {msg.msg_metadata.follow_ups.map((metadata, metaIndex) => (
                          <Button
                            key={metaIndex}
                            variant='text'
                            startIcon={<AddIcon sx={{ color: 'grey.700' }} />}
                            onClick={() => handleFollowUp(metadata)}
                            fullWidth
                            sx={{
                              color: 'grey.700',
                              textAlign: 'left',
                              justifyContent: 'flex-start',
                            }}
                          >
                            {metadata}
                          </Button>
                        ))}
                      </Box>
                    </>
                  )}
                </Typography>
              </Box>
            ))}
            <div ref={bottomRef} />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              flexGrow: 1,
              flexWrap: 'wrap',
            }}
          >
            <Typography variant="subtitle2" display={'flex'} color="grey.800">
              Ask a question about this course.
            </Typography>
          </Box>
        )}
      </Box>


      <Box
        sx={{
          position: 'fixed',
          bottom: 50,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          py: '3px',
          px: '5px',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          bgcolor: 'grey.300',
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
        }}
      >
        <TextField
          size="small"
          autoComplete="off"
          placeholder="Message Factora"
          variant="outlined"
          value={input}
          multiline
          autoFocus
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSend();
            }
          }}
          sx={{
            width: '90%',
            maxHeight: '30vh',
            overflow: 'auto',
            bgcolor: 'transparent',
            '& .MuiOutlinedInput-root': {
              border: 'none',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        />
        {loadingResponse ? (
          <LinearProgress sx={{ width: '100px', height: '5px' }} />
        ) : (
          <IconButton
            disabled={input.trim() === ''}
            onClick={handleSend}
            sx={{
              color: 'white',
              boxShadow: 'none',
              border: '0.5px solid grey',
              padding: '5px',
              bgcolor: '#027d95',
            }}
          >
            <ArrowUpwardIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default ChatWindowMobile;
